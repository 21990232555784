<template>
  <div :class="bgClass" :style="size" />
</template>

<script>
export default {
  name: 'AppRule',

  props: {
    width: {
      type: String,
      default: '1px',
    },
    height: {
      type: String,
      default: '1px',
    },
    color: {
      type: String,
      default: 'grey-200',
    },
  },
  computed: {
    bgClass() {
      return 'bg-' + this.color
    },
    size() {
      return {
        width: this.width,
        height: this.height,
      }
    },
  },
}
</script>
